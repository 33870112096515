import React, { useState, useEffect } from 'react';
import './App.css';

const API_KEY = '5c989c05fae5450d80373e9810586f28'; // Replace with your actual API key
const API_URL = `https://newsapi.org/v2/everything?q=real%20estate&apiKey=${API_KEY}`;

function App() {
  const [articles, setArticles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    async function fetchNews() {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();
        if (data.status === 'ok') {
          setArticles(data.articles.slice(0, 5)); // Limit to 5 articles for the carousel
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    }
    fetchNews();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % articles.length);
    }, 7000); // Auto-scroll every 5 seconds
    return () => clearInterval(interval); // Clear interval on unmount
  }, [articles]);

  const goToPrevious = () => {
    setCurrentIndex(currentIndex === 0 ? articles.length - 1 : currentIndex - 1);
  };

  const goToNext = () => {
    setCurrentIndex((currentIndex + 1) % articles.length);
  };

  return (
    <div className="carousel-container">
      
      {articles.length > 0 ? (
        <div className="carousel">
          <button className="arrow-button" onClick={goToPrevious}>&#8592;</button> {/* Left arrow */}
          <div className="carousel-item">
            <img 
              src={articles[currentIndex].urlToImage || 'https://via.placeholder.com/600x400'} 
              alt={articles[currentIndex].title} 
              className="news-image"
            />
            <h2>
              <a href={articles[currentIndex].url} target="_blank" rel="noopener noreferrer">
                {articles[currentIndex].title}
              </a>
            </h2>
            <p>{articles[currentIndex].description || 'No description available'}</p>
          </div>
          <button className="arrow-button" onClick={goToNext}>&#8594;</button> {/* Right arrow */}
        </div>
      ) : (
        <p>Loading news...</p>
      )}
    </div>
  );
}

export default App;
